





























import { Component, Vue } from 'vue-property-decorator';
import { User } from 'firebase/auth';
// eslint-disable-next-line
import firebaseAuth from '../services/FirebaseAuthentication';
import firebaseDatabase from '../services/FirebaseDatabase';

@Component
export default class HorcruxAdmin extends Vue {
  users: any[] = [];

  public mounted() : void {
    firebaseDatabase.GetAllUsers(async (d) => {
      this.users = d;
      this.users.sort((a, b) => ((a.totalHorcruxes < b.totalHorcruxes) ? 1 : -1));
    });
  }
}
