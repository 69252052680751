
























import { Component, Vue } from 'vue-property-decorator';
import { User } from 'firebase/auth';
// eslint-disable-next-line
import firebaseAuth from '../services/FirebaseAuthentication';
import firebaseDatabase from '../services/FirebaseDatabase';

@Component
export default class Home extends Vue {
  user : User | null = null;

  profile: any = null;

  public mounted() : void {
    firebaseAuth.GetCurrentUser(async (user: User|null) => {
      this.user = user;
      if (this.user === null) {
        return;
      }
      if (this.user !== null) {
        firebaseDatabase.GetUserProfile(this.user.uid, async (p) => {
          this.profile = p;
          if (this.profile === undefined && this.user !== null) {
            firebaseDatabase.SaveUserProfile({
              id: this.user.uid,
              displayName: this.user.displayName,
              email: this.user.email,
              photoURL: this.user.photoURL,
            });
          }
        });
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async googleSignIn() : Promise<void> {
    firebaseAuth.GoogleAuthenticate();
  }

  async logout() : Promise<void> {
    firebaseAuth.Logout();
    this.user = null;
  }
}
