<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/horcrux">Horcrux Hunt</router-link> |
      <router-link to="/drinks">Drinks</router-link> <!-- |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view/>
  </div>
</template>

<script>
import firebaseSetup from './services/FirebaseSetup';

firebaseSetup.Setup();

export default {
  name: 'App',
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Harry Potter Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Harry Potter Regular'), url('assets/fonts/HARRP___.woff') format('woff');
}
@font-face {
    font-family: 'harry_pregular';
    src: url('assets/fonts/harryp-mvz6w-webfont.woff2') format('woff2'),
         url('assets/fonts/harryp-mvz6w-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@import url('https://fonts.googleapis.com/css2?family=Amarante&display=swap');

* {
  box-sizing: border-box;
}

body {
  background: url(assets/paper-bg.jpg);
  background-size: 50%;
}
#app {
  margin: 0 20px;
  font-family: 'Amarante', cursive;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Harry Potter", harry_pregular, Avenir, Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 28px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}

a {
  padding: 3px;
  background-color: rgba(255,255,255,0.50);
}

#nav {
  padding: 30px 0;

  a {
    font-weight: bold;
    color: #222;
    padding: 5px 10px;
    text-decoration: none;

    &.router-link-exact-active {
      color: #FA6400;
    }
  }
}

button, input, textarea, select {
  font-family: 'Amarante', cursive;
}

button {
  border: 5px solid #222;
  background: rgba(255,255,255,0.50);
  color: #222;
  font-size: 18px;
  padding: 5px 10px;
}
button:disabled {
  opacity: .5;
}
button.processing {
  border-color: #FA6400;
  color: #FA6400;
}
input {
  width: 100%;
  padding: 10px;
  background: rgba(255,255,255,0.50);
  color: #222;
  border: 5px solid #222;
}
</style>
