<template>
  <div class="drinks">
    <HorcruxAdminComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import HorcruxAdminComponent from '@/components/HorcruxAdmin.vue';

export default {
  name: 'HorcruxAdmin',
  components: {
    HorcruxAdminComponent,
  },
};
</script>
