import Vue from 'vue';
import moment from 'moment';
import VueMask from 'v-mask';
import App from './App.vue';
import router from './router';

Vue.use(VueMask);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
Vue.filter('formatTime', (value) => {
  if (value) {
    return moment(String(value)).format('h:mm a');
  }
  return null;
});
