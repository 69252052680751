import axios from 'axios';

export default {
  async SendText(phoneNumber, message) {
    const cleanedPhone = phoneNumber.replace(/\D/g, '');
    const cleanedMessage = encodeURIComponent(message);
    const response = await axios.post(`https://brillhalloween-7736.twil.io/sendtext?To=1${cleanedPhone}&Body=${cleanedMessage}`, {});
    console.log(response);
  },
};
