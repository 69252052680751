import {
  getAuth, GoogleAuthProvider, signInWithPopup,
} from 'firebase/auth';

export default {
  async GoogleAuthenticate() {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential != null) {
          // const token = credential.accessToken;
          // The signed-in user info.
          // const { user } = result;
          console.log(result);
        }
        // ...
      }).catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const { email } = error;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log(error);
      });
  },
  async GetCurrentUser(callback) {
    const auth = await getAuth();
    auth.onAuthStateChanged((firebaseUser) => {
      callback(firebaseUser);
    });
  },
  async Logout() {
    const auth = await getAuth();
    return auth.signOut();
  },
};
