<template>
  <div class="drinks">
    <DrinksAdminComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import DrinksAdminComponent from '@/components/DrinksAdmin.vue';

export default {
  name: 'Drinks',
  components: {
    DrinksAdminComponent,
  },
};
</script>
