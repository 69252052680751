









































































































import { Component, Vue } from 'vue-property-decorator';
import { User } from 'firebase/auth';
// eslint-disable-next-line
import moment from 'moment';
import firebaseAuth from '../services/FirebaseAuthentication';
import firebaseDatabase from '../services/FirebaseDatabase';
import drinkstatus from '../models/drinkstatus';

@Component
export default class Drinks extends Vue {
  user : User | null = null;

  drinkstatus: any = drinkstatus;

  profile: any = null;

  drinks: any[] = [];

  data: any = {};

  phoneNumError = false;

  orderingDrink = '';

  public mounted() : void {
    this.data = {
      inputVal: '',
    };
    firebaseAuth.GetCurrentUser(async (user: User|null) => {
      this.user = user;
      if (this.user === null) {
        this.$router.push('/');
        return;
      }
      if (this.user !== null) {
        firebaseDatabase.GetUserProfile(this.user.uid, async (p) => {
          this.profile = p;
        });
        firebaseDatabase.GetUsersDrinks(this.user.uid, async (d) => {
          this.drinks = d;
        });
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public updateProfile(): void {
    if (!this.user) {
      return;
    }

    if (this.user !== null) {
      firebaseDatabase.GetUserProfile(this.user.uid, async (p) => {
        this.profile = p;
        if (this.profile && this.user) {
          const newProfile = this.profile;

          this.phoneNumError = false;
          const phoneNum = this.data.inputVal.replace(/\D/g, '');

          if (phoneNum.length !== 10) {
            this.phoneNumError = true;
            return;
          }

          newProfile.phoneNumber = phoneNum;

          firebaseDatabase.SaveUserProfile(newProfile);
          console.log('profile updated');

          firebaseDatabase.SaveUserProfile(newProfile);
        }
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async orderDrink(nameOfDrink:string) : Promise<void> {
    if (!this.user) {
      return;
    }
    this.orderingDrink = nameOfDrink;
    firebaseDatabase.AddDrink(this.user.uid, {
      drinkName: nameOfDrink,
      status: drinkstatus.entered,
      orderedTimestamp: moment(),
    }).then(() => {
      this.orderingDrink = '';
      console.log('drink ordered');
      const element = this.$refs.orders;
      if (element) {
        (element as any).scrollIntoView();
      }
    });
  }
}
