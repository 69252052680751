<template>
  <div class="drinks">
    <DrinksComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import DrinksComponent from '@/components/Drinks.vue';

export default {
  name: 'Drinks',
  components: {
    DrinksComponent,
  },
};
</script>
