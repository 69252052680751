<template>
  <div class="home">
    <HorcruxHunt />
  </div>
</template>

<script>
// @ is an alias to /src
import HorcruxHunt from '@/components/HorcruxHunt.vue';

export default {
  name: 'Horcrux',
  components: {
    HorcruxHunt,
  },
};
</script>
