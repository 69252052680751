



































































































import { Component, Vue } from 'vue-property-decorator';
import { User } from 'firebase/auth';
// eslint-disable-next-line
import firebaseAuth from '../services/FirebaseAuthentication';
import firebaseDatabase from '../services/FirebaseDatabase';
import twilioService from '../services/TwilioService';

@Component
export default class HorcruxHunt extends Vue {
  user : User | null = null;

  profile: any = null;

  public mounted() : void {
    let horcruxFound = this.$route.query.horcrux;
    firebaseAuth.GetCurrentUser(async (user: User|null) => {
      this.user = user;
      if (this.user === null) {
        this.$router.push('/');
        return;
      }
      if (this.user !== null) {
        firebaseDatabase.GetUserProfile(this.user.uid, async (p) => {
          this.profile = p;
          if (this.profile === undefined && this.user !== null) {
            firebaseDatabase.SaveUserProfile({
              id: this.user.uid,
              displayName: this.user.displayName,
              email: this.user.email,
              photoURL: this.user.photoURL,
            });
          }
          if (this.profile && this.user && horcruxFound) {
            const newProfile = this.profile;
            const horcruxFoundStr = String(horcruxFound);
            console.log(horcruxFoundStr);
            if (!newProfile.horcruxesFound) {
              newProfile.horcruxesFound = {};
            }
            newProfile.horcruxesFound[atob(horcruxFoundStr)] = true;
            horcruxFound = '';
            await firebaseDatabase.SaveUserProfile(newProfile);
            const element = this.$refs[atob(horcruxFoundStr)];
            if (element) {
              (element as any).scrollIntoView();
            }
            if (user != null
                && newProfile.horcruxesFound.tomRiddle
                && newProfile.horcruxesFound.marvoloGaunt
                && newProfile.horcruxesFound.salazarSlitherin
                && newProfile.horcruxesFound.helgaHufflepuff
                && newProfile.horcruxesFound.rowenaRavenclaw
                && newProfile.horcruxesFound.harryPotter
                && newProfile.horcruxesFound.nagini) {
              await twilioService.SendText('7158923215', `${user.displayName} found all the Horcruxes!`);
            }
          }
        });
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async googleSignIn() : Promise<void> {
    firebaseAuth.GoogleAuthenticate();
  }

  async logout() : Promise<void> {
    firebaseAuth.Logout();
    this.user = null;
  }
}
