import { initializeApp } from 'firebase/app';

export default {
  Setup() {
    const firebaseConfig = {
      apiKey: 'AIzaSyCYCTE_ZqPQlCm-5pfnp88eNKggMB8s0Zg',
      authDomain: 'brill-halloween.firebaseapp.com',
      projectId: 'brill-halloween',
      storageBucket: 'brill-halloween.appspot.com',
      messagingSenderId: '1080189318452',
      appId: '1:1080189318452:web:8ab316c095c1015e662161',
      measurementId: 'G-VHTQP9JTST',
    };

    initializeApp(firebaseConfig);
  },
};
