import {
  doc, setDoc, getFirestore, collection, onSnapshot, query, where,
} from 'firebase/firestore';
import _ from 'underscore';
import moment from 'moment';

const db = getFirestore();

export default {
  async SaveUserProfile(profile) {
    await setDoc(doc(db, 'users', profile.id), profile);
  },
  async GetUserProfile(userId, callback) {
    onSnapshot(doc(db, 'users', userId), (document) => {
      callback(document.data());
    });
  },
  async AddDrink(userId, drink) {
    const d = new Date();
    const data = drink;
    data.userId = userId;
    const id = userId
               + d.getFullYear()
               + d.getMonth()
               + d.getDate()
               + d.getHours()
               + d.getMonth()
               + d.getSeconds();
    data.orderedTimestamp = data.orderedTimestamp.valueOf();
    await setDoc(doc(db, 'drinks', id), data);
  },
  async GetUsersDrinks(userId, callback) {
    const q = query(collection(db, 'drinks'), where('userId', '==', userId));
    onSnapshot(q, (querySnapshot) => {
      const drinks = [];
      querySnapshot.forEach((d) => {
        const data = d.data();
        data.id = d.id;
        data.orderedTimestamp = moment(data.orderedTimestamp);
        drinks.push(data);
      });

      callback(_.sortBy(drinks, (d) => {
        if (Number.isNaN(d.orderedTimestamp.valueOf())) {
          return -1;
        }
        return d.orderedTimestamp.valueOf();
      }).reverse());
    });
  },
  async GetAllDrinks(callback, status = null) {
    let users = [];
    let drinks = [];
    const callCallback = () => {
      _.map(drinks, (drink) => {
        const newDrink = drink;
        newDrink.user = _.find(users, (user) => (user.id === drink.userId));
        return newDrink;
      });
      callback(drinks);
    };
    onSnapshot(query(collection(db, 'users')), (querySnapshot) => {
      users = [];
      querySnapshot.forEach((d) => {
        const data = d.data();
        data.id = d.id;
        users.push(data);
      });
      callCallback();
    });
    const q = (status === null)
      ? query(collection(db, 'drinks'))
      : query(collection(db, 'drinks'), where('status', '==', status));
    onSnapshot(q, (querySnapshot) => {
      drinks = [];
      querySnapshot.forEach((d) => {
        const data = d.data();
        data.id = d.id;
        data.orderedTimestamp = moment(data.orderedTimestamp);
        drinks.push(data);
      });
      callCallback();
    });
  },
  async MarkDrinkStatus(drink, status = null) {
    const updatedDrink = drink;
    updatedDrink.status = status;
    updatedDrink.orderedTimestamp = moment(updatedDrink.orderedTimestamp).valueOf();
    await setDoc(doc(db, 'drinks', drink.id), updatedDrink);
  },
  async GetAllUsers(callback) {
    let users = [];
    onSnapshot(query(collection(db, 'users')), (querySnapshot) => {
      users = [];
      querySnapshot.forEach((d) => {
        const data = d.data();
        data.id = d.id;
        data.totalHorcruxes = (data.horcruxesFound) ? Object.keys(data.horcruxesFound).length : 0;
        users.push(data);
      });
      callback(users);
    });
  },
};
