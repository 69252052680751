





























import { Component, Vue } from 'vue-property-decorator';
import { User } from 'firebase/auth';
// eslint-disable-next-line
import firebaseAuth from '../services/FirebaseAuthentication';
import firebaseDatabase from '../services/FirebaseDatabase';
import twilioService from '../services/TwilioService';
import drinkstatus from '../models/drinkstatus';

@Component
export default class DrinksAdmin extends Vue {
  completedDrinks: any[] = [];

  todoDrinks: any[] = [];

  completingDrink = '';

  public mounted() : void {
    firebaseDatabase.GetAllDrinks(async (d) => {
      this.completedDrinks = d;
    }, drinkstatus.readyforpickup);
    firebaseDatabase.GetAllDrinks(async (d) => {
      this.todoDrinks = d;
    }, drinkstatus.entered);
  }

  // eslint-disable-next-line class-methods-use-this
  async markAsReady(drink:any) : Promise<void> {
    this.completingDrink = drink.id;
    if (drink && drink.user && drink.user.phoneNumber) {
      await twilioService.SendText(drink.user.phoneNumber,
        `Your ${drink.drinkName} is ready! You can pick it up now at the speakeasy.`);
    }
    // await firebaseDatabase.MarkDrinkStatus(drink, drinkstatus.readyforpickup);
    // this.completingDrink = '';
    // console.log('drink ordered');
    firebaseDatabase.MarkDrinkStatus(drink, drinkstatus.readyforpickup).then(() => {
      this.completingDrink = '';
      console.log('drink ordered');
    });
  }
}
